import styled from 'styled-components'
import { Row } from '@shipt/design-system-layouts'
import { MenuIcon } from '@shipt/design-system-icons'
import { Body } from '@shipt/design-system-typography'
import { TransparentButton } from '@/features/shared/elements/Buttons'
import { screenSizes } from '@shipt/design-system-themes'
import { LayoutContainer } from '@/features/shared/styles/layout'
import { HeaderLogoLink } from '@/features/shared/components/Headers/HeaderLogoLink'
import { useUserId } from '@/features/account/services/User/hooks'
import { ClientOnly } from '@/features/shared/components/ClientOnly'
import { NextLink } from '@/features/shared/elements/Link'
import { spacing } from '@/features/shared/theme/tokens'
import { routes } from '@shared/constants/routes'
import { useMediaQuery } from '@/features/shared/hooks/useMediaQuery'
import { HeaderUserLinks } from '@/features/shared/components/Headers/HeaderUserLinks'
import {
  MAKE_MONEY_LINKS,
  MEMBERSHIP_POPOVER_LINKS,
  SAME_DAY_DELIVERY_LINKS,
} from '@/features/shared/constants/layouts'
import dynamic from 'next/dynamic'
import { useHandleDialog } from '@/features/shared/state/Dialog/useHandleDialog'
import { DynamicFullSizeMobileMenuModal } from '@/features/shared/components/Modal/FullSizeMobileMenuModal/DynamicFullSizeMobileMenuModal'
import { MobileMenu } from '@/features/shared/components/Headers/UngatedHeader/MobileMenu'

const CategoryPopover = dynamic(
  async () => (await import('./NavPopover/CategoryPopover')).CategoryPopover
)
const LinksPopover = dynamic(
  async () => (await import('./NavPopover/LinksPopover')).LinksPopover
)

type Props = { hideNavLinks?: boolean }

export const UngatedHeaderContent = ({ hideNavLinks }: Props) => {
  const userId = useUserId()
  const { screenSm } = useMediaQuery()
  const linkSize = screenSm ? 'sm' : 'md'
  const { openDialog, closeDialog } = useHandleDialog()

  return (
    <LayoutContainer enableXLarge>
      <Nav>
        <Row align="center">
          <MenuButton
            onClick={() =>
              openDialog(DynamicFullSizeMobileMenuModal, {
                renderMenu: () => <MobileMenu onClose={closeDialog} />,
              })
            }
            aria-label="Open hamburger menu dialog"
          >
            <MenuIcon />
          </MenuButton>
          <HeaderLogoLink />
          <ClientOnly>
            {!userId && !hideNavLinks && (
              <NavLinkWrapper spacing="xl">
                <LinksPopover
                  title="Same day delivery"
                  links={SAME_DAY_DELIVERY_LINKS}
                  url={routes.DELIVERY.url}
                />
                <NextLink href={routes.STORES.url}>
                  <UngatedNavLink strong>Stores</UngatedNavLink>
                </NextLink>
                <CategoryPopover />
                <LinksPopover
                  title="Memberships"
                  links={MEMBERSHIP_POPOVER_LINKS}
                />
                <LinksPopover
                  title="Make money with us"
                  links={MAKE_MONEY_LINKS}
                />
              </NavLinkWrapper>
            )}
          </ClientOnly>
        </Row>
        <HeaderUserLinks linkSize={linkSize} />
      </Nav>
    </LayoutContainer>
  )
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  height: 4.5rem;
  padding: 0 1.25rem;

  @media ${screenSizes.tablet} {
    padding: 0;
  }
`

const MenuButton = styled(TransparentButton)`
  margin-right: 1rem;

  @media ${screenSizes.tablet} {
    margin-right: 1.5rem;
  }

  @media ${screenSizes.smDesktop} {
    display: none;
  }
`

const NavLinkWrapper = styled(Row)`
  display: none;

  @media ${screenSizes.smDesktop} {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }
`

const UngatedNavLink = styled(Body)`
  position: relative;
  color: ${({ theme }) => theme.plum};

  &:hover,
  &:focus {
    &::after {
      display: block;
      position: absolute;
      margin-top: ${spacing('xs')};
      left: calc(50% - 1rem);
      content: '';
      width: 2rem;
      height: 0.125rem;
      background-color: ${({ theme }) => theme.green};
    }
  }
`
